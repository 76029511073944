import React from "react"

const Instruction = ({ text, number }) => (
    <li>
    <div className="grid grid-cols-12 grid-rows-1">
        <span className="instructionNumber text-left items-start">
                { number }
        </span>
        <div className="col-span-11">
            <p className="instructionText">
                { text }
            </p>
        </div>
    </div>
        
    </li>
)

export default Instruction
