import React from "react"
import { Link } from "gatsby"

const Tag = ({ text }) => (
    <div className="tagContainer">
        <Link className="tagText" to={`/tag/${text}`}>{text}</Link>
    </div>
)

export default Tag
