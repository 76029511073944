import React from "react"

import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Ingredient from "../components/ingredient"
import Instruction from "../components/instruction"
import Tag from "../components/tag"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"

const Recipe = ({data}) => {
  const recipe = data.contentfulRecipe;
  const ings = recipe.ingredients.ingredients;
  const inst = recipe.instructions.instructions;
  const notes = recipe.notes
  let ingsList;
  let ingsJoinedList = [];
  let instList;
  let instJoinedList = [];
  let notesList;
  let notesJoinedList = [];
  let isNotes = notes !== null && notes !== undefined;

  if (ings.includes(`\n`))
  {
    // If there are blank new lines, remove them
    let cleaned = ings.includes(`\n\n`) ? ings.replace('\n\n', '\n') : ings;
    // ingsList = cleaned.split("\n")
    ingsList = cleaned.split("\n").filter(Boolean);
    
    for (let i=0; i < ingsList.length; i++)
    {
      let isHeader = false;
      let isBlank = false;
      let firstChar = ingsList[i].charAt(0);

      isHeader = firstChar === `*`;
      isBlank = firstChar === '\\';
      ingsList[i] = ingsList[i].substring(1, firstChar === `*` ? (ingsList[i].length - 1) : ingsList[i].length);

      if (!isBlank) { 
        ingsJoinedList.push([ingsList[i], isHeader]);
      }
    }
  }
  if (inst.includes(`\n`))
  {
    instList = inst.split("\n")
    for (let i=0; i < instList.length; i++)
    {
      let num = instList[i].substring(0, instList[i].indexOf('.'))
      instList[i] = instList[i].substring((instList[i].indexOf('.') + 2), instList[i].length);

      instJoinedList.push([instList[i], num]);
    }
  }
  if (isNotes)
  {
    notesList = notes.notes.split("\n")
    for (let i=0; i < notesList.length; i++)
    {
      notesList[i] = notesList[i].substring(1, notesList[i].length);
      notesJoinedList.push([notesList[i]]);
    }
  }

  return (
  <Layout>
    <SEO title={recipe.title} description={recipe.description} />
    <div id="top"></div>
    <div>
    <div className="contentContainer pt-6" id="title">
      <Link to={`/author/${recipe.author}`}>
        <span className="author">{`${recipe.author}'s`}</span>
      </Link>      
      <h1>{recipe.title}</h1>
    </div>
    <div className="contentContainer">
    <div>
      <section className="py-2">
        <span className="descriptionText">{recipe.description}</span>
      </section>
      <section className="py-2">
        {recipe.images.map(img => (
          <Image className="object-cover max-h-48" fluid={img.fluid} key={img.fluid.src} alt={img.title} />
        ))}
      </section>
      <section className="py-2">
        <div className="grid grid-cols-4 grid-flow-col">
          <div className="col-span-2">
            <span className="metaLabel">Yield</span>
            <span className="metaContent">{recipe.yield}</span>
          </div>
          <div>
          <span className="metaLabel">Prep Time</span>
            <span className="metaContent">{recipe.prepTime}</span>
          </div>
          <div>
          <span className="metaLabel">Total Time</span>
            <span className="metaContent">{recipe.readyTime}</span>
          </div>
        </div>
      </section>
      <section className="flex py-2 gap-1">
        {recipe.tags.map((tag) => (
              <Tag text={tag} />
            ))}
      </section>
      <hr/>
      <section className="py-6" id="ingredients">
        <span class="sectionLabel">Ingredients</span>
        <div>
          <ul>
            {ingsJoinedList.map((ing) => (
              <Ingredient text={ing[0]} isHeader={ing[1]} />
            ))}
          </ul>
        </div>
      </section>
      <hr/>
      <section className="py-6" id="instructions">
      <span class="sectionLabel">Instructions</span>
        <div>
          <ol>
            {instJoinedList.map((inst) => (
              <Instruction text={inst[0]} number={inst[1]} />
            ))}
          </ol>
        </div>
      </section>
      <hr/>
      <section className={isNotes ? `py-6` : `hidden`} id="notes">
      <span class="sectionLabel">Notes</span>
        <div>
          <ul>
          {notesJoinedList.map((note) => (
              <Instruction text={note[0]} />
            ))}
          </ul>
        </div>
        
      </section>
      <div className="text-center justify-items-center items-center text-sm font-medium opacity-70 py-4">
          <button onClick={() => scrollTo('#top')}>Return to Top</button>
        </div>
    </div>
    </div>
    </div>
    
  </Layout>
  )
}

export default Recipe

export const pageQuery = graphql`
    query recipeQuery($slug: String!) {
      site {
        siteMetadata {
          title
        }
      }
      contentfulRecipe(slug: { eq: $slug }) {
        title
        instructions {
          instructions
        }
        ingredients {
          ingredients
        }
        notes {
          notes
        }
        images { fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid
          }
        }
        yield
        prepTime
        description
        rating
        readyTime
        tags
        author 
        createdAt(formatString: "MM-DD-YYYY")
      }    
    }
`